.iconDisabled {
  margin-right: 6px;
  opacity: 0.5;
}

.text {
  color: var(--grey22);
}

.popover {
  border-radius: 6px;
  padding: 10px 14px;
}

.popoverText {
  padding: 0;
  line-height: 19.2px;
  font-weight: 300;
}
